// src/data/projects.js

const importAllImages = (r) => r.keys().map(r);

// Importing images for each project
const laserPoster = importAllImages(require.context('../assets/images/laserPoster', false, /\.(png|jpe?g|svg)$/));
const wiiTanks = importAllImages(require.context('../assets/images/wiiTanks', false, /\.(png|jpe?g|svg)$/));
const rp2040PCB = importAllImages(require.context('../assets/images/rp2040PCB', false, /\.(png|jpe?g|svg)$/));
const IDP = importAllImages(require.context('../assets/images/idp', false, /\.(png|jpe?g|svg)$/));
const cvproject = importAllImages(require.context('../assets/images/cvproject', false, /\.(png|jpe?g|svg)$/));
const applied = importAllImages(require.context('../assets/images/applied', false, /\.(png|jpe?g|svg)$/));


const projectsData = {
  1: {
    title: 'Laser Engraved LED Picture Frame',
    images: laserPoster,
    shortDescription: 'Simple wooden poster that evolved into a stunning LED-lit picture frame, blending custom laser engraving, 3D printing, and DIY electronics.',
    description: `Originally starting as simple laser engraved wooden posters, it evolved to become a picture frame.  The original artwork is hand traced in Inkscape in order to get a vectorized image of the poster.  
    That image is laser engraved onto an 10" x 10" piece of wood.  The image is then mirrored and laser engraved again onto an 10" x 10" piece of acrylic.  
    The acrylic is mirrored so when it is turned around and placed on top of the wood, you cannot feel the engraved lines.<br></br>
    The frames were custom designed in Fusion 360 and 3D printed. The wood and acrylic pieces are sandwiched between the back and front of the 3D printed frame. The front attaches using integrated magnets and the LED strip slides
    directly into the frame. After soldering on the magnetic connector, the frame is ready to be plugged into the control box.<br></br>
    The control box was also designed in Fusion 360 featuring a Meanwell 350W 12V PSU. This PSU powers the WLED controller and the 8 LED strips for a total of 1,152 LEDs. Overall this project was a great learning experience in 3D printing, laser cutting, and electronics.`,
    tags: ['3D Printing', 'Laser Cutting', 'CAD', 'WLED'],
    type: 'personal',
    dateRange: 'Fall 2022 - Fall 2024'
  },
  3: {
    title: 'Custom RP2040 PCB',
    images: rp2040PCB,
    shortDescription: 'Designing a custom PCB for the Raspberry Pi RP2040 microcontroller to create mini LED posters',
    description: `Following the design documents for the RP2040, a custom PCB was designed in KiCAD. This was used as a project for incoming first-year engineers. The board features 5 WS2812B LEDs, a USB-C connector, and three potentiometers. The board was designed to be small, compact and to integrate into a 3D printed base to light up an acrylic base.<br></br>
    Additionally the board features access several GPIO pins, along with ground and voltage pins. This allows the first-year engineers to further add modifications to this board as they see fit. *Note: pictures do not accurately represent final KiCAD design`,
    tags: ['RP2040','Micro Python','CAD','PCB Design'],
    type: 'personal',
    dateRange: 'Fall 2024'
  },
  6: {
    title: 'Wii Tanks Reinforcement Learning Agent',
    images: wiiTanks,
    shortDescription: 'Utilizing reinforcement learning to train a bot to play the Tanks minigame from Wii Play using PyTorch and OpenCV.',
    description: `Tanks is a minigame featured in Nintendo’s Wii Play video game. Using Dolphin (a Wii and Gamecube emulator for computers), the game can be played on a computer along with emulated controls. With this in mind, the goal of this project was to create a bot in Python that would be able to complete the various levels of the game.<br></br>
    The first approach was using PyAutoGUI and OpenCV. OpenCV was used so the bot can “see” everything within the game while PyAutoGUI was used to control the game. A cascade classifier was trained using 150 images of the Player’s tank. After training, the classifier was able to recognize all the various types of tanks, but not differentiate between them. So the player tank and an enemy tank were the same thing in the eyes of the bot. In order to get around this, color masks were drawn around specific colors. The player’s tank is blue, so everything but blue was masked, the classifier recognized the tank and it was labeled appropriately. Inversely, blue was masked, and the classifier recognized all the enemy tanks. This approach worked successfully, but I ended up pivoting away from it in order to use a neural network.<br></br>
    The second approach was using the Stable-Baselines3 library. This library has lots of built in functions to train reinforcement learning models. A custom environment was created for the bot and a reward system was implemented. The rewards system was solely focused on eliminating enemy tanks, as time alive would just encourage the bot to stay alive, and not actively eliminate tanks. Using OpenCV again, the matchTemplate() function was used to recognize the various numbers in the specific score font. Simple image to text couldn’t be used since it was not trained on that specific font and would output erratic numbers.   After successfully im plementing the environment and reward function, the bot would play the game unsuccessfully. After training f or ~20000 time steps, the bot progressed to the second level but not consistently. More training has to be done.`,
    tags: ['Machine Learning','Python','PyTorch','OpenCV','Reinforcement Learning'],
    type: 'personal',
    dateRange: 'Summer 2023'
  },
  5: {
    title: 'Infrared Radio Teletype Project',
    images: IDP,
    shortDescription: 'Building a simple communication system using only an IR LED, an IR photodiode, and two Arduinos, earning best in course.',
    description: `During my Sophomore Spring semester, I took Virginia Tech's ECE Sophomore design class. The project I chose to do was this Infrared Radio Teletype project. The basic idea of the project was to send and receive messages only using an IR LED, an IR photodiode, and two Arduinos. I was able to achieve sending and receiving a  message over 30 ft.<br></br>
    Using Arduino C, I programmed a method of decoding a string into 8-bit binary (ASCII representation) with a start and stop bit for each letter. This program would take a string, break it down into letters and then each letter would get broken down into 10 bits. The IR LED would then be turned on and off at specific frequencies depending if it was a 1 (2125 Hz) or a 0 (2295 Hz) (These frequencies were project spec).<br></br>
    On the receiving end, a transimpedance amplifier was used to convert the photodiode's current into a readable voltage. This voltage was then passed through a series of bandpass filters to reduce noise and to single out either a 1 or 0. The receiving Arduino used a digital comparator to compare whether the receiving signal was noise or the real deal. If it was the real deal, the frequency was measured appropriately using the input capture registers and assigned to a 1 or 0. The program then worked nearly identical to the sending side, but in reverse.<br></br>
    To go above and beyond, I built a T-9 dialing device that would write a message on an OLED screen and send the message. I ended with an A in the class and a report on this project is available on request.`,
    tags: ['Filter Design','Arduino','KiCAD'],
    type: 'school',
    dateRange: 'Spring 2023'
  },
  2: {
    title: 'First Layer Error Detection',
    images: cvproject,
    shortDescription: 'Utilizing traditional computer vision to detect first-layer adhesion errors in 3D printing, earning best in course.',
    description: `First-layer adhesion error is a common issue encountered by novices during 3D printing, resulting in print defects and failed prints. Stemming from improperly set z-offset, an inconsistent bed mesh, or a sagging gantry, these issues prevent the first layer of the print from setting in the correct position causing time and filament to be wasted if left unnoticed.<br></br>
    In a series of two steps, we used computer vision from a camera mounted above a 3D printer bed to analyze the quality of the first layer to ensure that the rest of the print would be successful. First, a trained YOLO model is used to detect the “spaghetti-ing” of the filament, followed by a script to ensure the first layer is in the correct position while identifying the quality from the shape of the printing patterns.<br></br>
    With this system, we were able to identify print failures that occurred via spaghetti-ing, sending a call to the printer to stop the printing process. We were also able to correctly identify when and how many parts of an attempted print were missing, along with the reasons behind low-quality yet functional prints.<br></br>
    This project was a great learning experience for traditional computer vision. We were able to get a 93% success rate on identifying if the first layer was too high, too squished or perfect. I highly recommend reading the full paper <a href="https://cv.viola.dev">here</a>`,
    tags: ['Python','YOLO','OpenCV','Traditional Computer Vision'],
    type: 'school',
    dateRange: 'Fall 2024'
  },
  4: {
    title: 'MIPS Simulator',
    images: applied,
    shortDescription: 'Developing a MIPS simulator in C++ with CLI and Qt GUI',
    description: `During my time taking ECE 3574: Applied Software Design, we had a semester long project creating a MIPS simulator from scratch. We had to implement the MIPS instruction set, memory, and registers. The simulator had to be able to run MIPS assembly code and output the results. The project was split into a couple of notable parts: Tokenizing, Parsing, Virtual Machine, CLI and GUI<br></br>
    Tokenizing was the first step in the process. We had to take the MIPS assembly code and break it down into individual tokens. This was done using a series of regular expressions to identify the different parts of the MIPS code. The tokens were then passed to the parser. The parser then had to parse through the .data section and the .text section. This was mainly done with a loop and a long series of checking functions to determine the correct identifications.<br></br>
    Once correctly parsed, the MIPS code was then passed to the virtual machine. The virtual machine was the heart of the project. It had to be able to run the MIPS code and output the results. This was done by implementing each of the MIPS functions, and simulating registers and memory. Since this project built on top of itself, ensuring that your tokenizer and parser worked perfectly was essential. Once the virtual machine worked, building a CLI and GUI were relatively simple, just having to build entry points to interact to with the virtual machine.<br></br>
    The GUI was built with Qt and was able to view MIPS files, run the MIPS code and view the output of the memory and registers. It also had a step mode, to step through the code one line at a time, along with a run button to run the code all at once. *Note image used was not the final GUI`,
    tags: ['C++','Qt','MIPS'],
    type: 'school',
    dateRange: 'Spring 2024'
  },
};

export default projectsData;